<template>
  <div>
    <v-btn
      text
      plain
      :color="$vuetify.theme.dark ? '#fff' : '#222'"
      :ripple="false"
      @click="activityDownload"
      class="body-1 font-weight-bold"
      >{{ this.$t("activities.downloadActivity")
      }}<ph-cloud-arrow-down
        class="ml-3"
        :size="16"
        :color="$vuetify.theme.dark ? '#fff' : '#222'"
    /></v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PhCloudArrowDown } from "phosphor-vue";
import dateConvertor from "@/mixins/dateConvertor";
export default {
  components: {
    PhCloudArrowDown,
  },
  mixins: [dateConvertor],
  props: {
    direction: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      userIncome: "wallet/userIncome",
      userOutgoing: "wallet/userOutgoing",
    }),
  },
  methods: {
    activityDownload() {
      let heading = [
        ["Date", "Type", "Status", "Name", "TTU Amount", "Personal note"],
      ];

      const allUserActivities =
        this.direction === "income" ? this.userIncome : this.userOutgoing;

      allUserActivities.forEach((el) => {
        let activityStatus = "";
        switch (el.transferStatus) {
          case 0:
            activityStatus = "Completed";
            break;
          case 1:
            activityStatus = "Pending";
            break;
          case 2:
            activityStatus = "Rejected";
            break;
          case 3:
            activityStatus = "Expired";
            break;
          default:
            activityStatus = "Completed";
        }
        heading.push([
          this.$options.filters.convertToLocalFullDateTime(el.dateTime),
          el.activityType,
          activityStatus,
          el.otherName,
          el.activityAmount,
          el.transferRequestNotes,
        ]);
      });

      let csvRows = [];

      heading.forEach((el) => {
        csvRows.push(el.join(","));
      });

      let csvString = csvRows.join("\r\n");
      let link = document.createElement("a");

      link.href = "data:attachment/csv," + encodeURIComponent(csvString);
      link.target = "_blank";
      link.download = "activities.csv";

      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)::v-deep
  .v-btn__content {
  opacity: 1;
}
</style>
